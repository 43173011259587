import { mapGetters } from 'vuex';
import { required, email, maxLength } from 'vuelidate/lib/validators';
import password from '@/mixins/plugin/password';
import fadeHeight from '@/mixins/plugin/fadeHeight';
import error from '@/mixins/plugin/error';
import { address } from '@/mixins/plugin/address';
import { isPhoneNumber, isZipcode, isAlphanumSpecialWithMinLength, notSpace } from '@/helpers/validators';
//component
import FormRow from '@/components/FormRow.vue';
import SearchSelect from '@/components/SearchSelect.vue';
import UserMailSetting from '@/components/UserMailSetting.vue';
import userMailSetting from '@/mixins/module/userMailSetting';
//constant
import { RoleConstants } from '@/constants/role';

export default {
  name: 'SettingUserRegisterClient',
  data: function() {
    return {
      companyInputType: 'selected',
      formData: {
        nameSei: '',
        nameMei: '',
        email: '',
        password: '',
        companyId: '',
        company: {
          name: '',
          zipcode: '',
          prefectureId: '',
          municipalityId: '',
          streetAddress: '',
          buildingName: '',
          telephone: '',
        },
        receptionEmailFlag: 1,
        authEmailFlag: 0,
        role: [],
      },
      userPrivilegesData: [],
      prefixs: ['company'],
    };
  },
  validations() {
    const formData = {
      nameSei: { required, maxLength: maxLength(20), notSpace },
      nameMei: { required, maxLength: maxLength(20), notSpace },
      email: { required, email, maxLength: maxLength(50) },
    };
    if (!this.id || !!this.formData.password) {
      formData.password = { required, alphanumSpecialWithMinLength: isAlphanumSpecialWithMinLength(8) };
    }
    if (this.$permission.isGmoOrOffice()) {
      if (this.companyInputType === 'created') {
        formData.company = {
          name: { required, maxLength: maxLength(20) },
          zipcode: { required, isZipcode },
          prefectureId: { selectRequired: required },
          municipalityId: { selectRequired: required },
          streetAddress: { required, maxLength: maxLength(40) },
          buildingName: { maxLength: maxLength(40) },
          telephone: { required, isPhoneNumber },
        };
      } else if (!this.isClientChild) {
        formData.companyId = { selectRequired: required };
      }
    }
    return { formData };
  },
  computed: {
    ...mapGetters({
      companies: 'client/clientList',
      userPrivileges: 'common/userPrivileges',
      userInfo: 'auth/infor',
      userChildRoles: 'auth/userChildRoles'
    }),
    id: function() {
      return this.$route.params.id;
    },
    allCompanies() {
      const items = [];
      if (this.$permission.isGmo()) {
        this.companies.forEach((parentCompany) => {
          items.push({
            id: parentCompany.id,
            name: parentCompany.name
          })
          if (parentCompany?.children?.length) {
            parentCompany.children.forEach((childCompany) => {
              items.push({
                id: childCompany.id,
                name: childCompany.name,
                isChild: true
              })
            })
          }
        });
      } else if (!this.isClientChild) {
        items.push({
          id: this.currentParentCompany?.id,
          name: this.currentParentCompany?.name
        })
        this.companies.forEach((childCompany) => {
          items.push({
            id: childCompany.id,
            name: childCompany.name,
            isChild: true
          })
        })
      }
      return items;
    },
    currentParentCompany() {
      if (this.$permission.isOffice()) {
        return !this.userInfo?.company?.parent?.id ? this.userInfo?.company : this.userInfo?.company?.parent
      }

      return null;
    },
    currentChildCompany() {
      if (this.$permission.isOffice()) {
        return this.userInfo?.company?.parent?.id ? this.userInfo?.company : ""
      }

      return null;
    },
    isClientChild() {
      return this.$permission.isOffice() && !!this.currentChildCompany;
    },
    hasShopsRole() {
      return (this.formData.role || []).includes('shops');
    },
    hasCurrencyRole() {
      return (this.formData.role || []).includes('currency');
    }
  },
  mixins: [password, fadeHeight, error, userMailSetting, address({ formName: 'formData.company' })],
  components: {
    FormRow,
    SearchSelect,
    UserMailSetting,
  },
  methods: {
    handleSelectedCompanyId(company) {
      this.formData.companyId = company?.id || "";
      this.userPrivilegesData = this.resetUserPrivileges(company?.isChild);
      this.setUserPrivilegesData();
    },
    register: async function() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v.formData, 'user');
      } else {
        const data = {
          ...this.formData,
          companyId: this.formData.companyId || this.currentChildCompany?.id,
          receptionEmailFlag: this.formData.receptionEmailFlag ? 1 : 0,
          authEmailFlag: this.formData.authEmailFlag ? 1 : 0,
          company: {
            ...this.formData.company,
            prefectureId: this.formData.company.prefectureId,
            municipalityId: this.formData.company.municipalityId,
          },
          role: this.$permission.isOffice() ? this.formData.role.filter(item => this.userChildRoles.includes(item)) : this.formData.role
        };
        if (this.companyInputType === 'selected') {
          delete data.company;
        } else {
          this.id ? data.companyId = null : delete data.companyId;
        }
        if (this.id && this.formData.password === '') delete data.password;
        this.$store.dispatch('auth/getUserInfor');
        if (this.id) {
          const result = await this.$store.dispatch('settingUser/updateUser', {
            data: data,
            id: this.id,
            kind: RoleConstants.ROLE_OFFICE,
          });
          if (result) {
            if (this.userInfo.id === this.$route.params.id) {
              this.$store.dispatch('auth/getUserInfor');
            }
            await this.$router.push({ name: 'SettingUser' });
            this.$message.updated('accountUserInfo');
          }
        } else {
          const result = await this.$store.dispatch('settingUser/createUser', { data: data, kind: RoleConstants.ROLE_OFFICE });
          if (result) {
            await this.$router.push({ name: 'SettingUser' });
            this.$message.showSuccess('createdUserInfoSuccess');
          }
        }
      }
    },
    getCompanies() {
      if (this.$permission.isGmo()) {
        this.$store.dispatch('client/getClientList', {
          type: "all",
          limit: 9999
        });
      } else if (!this.isClientChild) {
        this.$store.dispatch('client/getClientList', {
          type: "children",
          limit: 9999,
          parentId: this.currentParentCompany ? [this.currentParentCompany.id] : null
        });
      }
      return null;
    },
    resetUserPrivileges(data) {
      if (data && this.formData.company?.parentId) {
        return (this.userPrivileges || []).filter(e => e.code !== 'manage_shop');
      }
      return this.userPrivileges;
    },
    handleUserPrivilege() {
      this.setUserPrivilegesData();
      this.formData.role.forEach((e, index) => {
        if (!this.hasShopsRole && e === 'manage_shop') {
          this.formData.role.splice(index, 1);
        } else if (!this.hasCurrencyRole) {
          this.formData.role = this.formData.role.filter(item => !['manage_currency', 'manage_qr'].includes(item));
        }
      });
    },
    checkUserPrivilegesData(data) {
      const filteredUserPrivileges = (data || []).map(privilege => {
        const hasShops = privilege.code === 'manage_shop' && !this.hasShopsRole;
        const hasChildShops = privilege.code === 'manage_shop' && this.$permission.isGmo() && !!this.userDetail?.company?.parent?.id;
        const hasCurrency = ['manage_currency', 'manage_qr'].includes(privilege.code) && !this.hasCurrencyRole;
        return {
          ...privilege,
          isShown: !(hasShops || hasCurrency || hasChildShops)
        }
      });
      return filteredUserPrivileges;
    },
    setUserPrivilegesData() {
      if (this.isClientChild) this.userPrivilegesData = Object.values(this.userPrivilegesData);
      this.userPrivilegesData = this.checkUserPrivilegesData(this.userPrivilegesData);
    }
  },
  watch: {
    'formData.company.prefectureId'(prefectureId) {
      this.getMunicipalities(prefectureId, this.formData.company.municipalityId);
    },
    companyInputType(val) {
      this.initialErrors();
      if (val === 'selected') {
        this.formData.companyId = '';
        this.errors.companyId = false;
      }
      if (this.id) {
        this.initialCompany = null;
        this.companyName = '';
      }
    },
    'formData.company.parentId'(companyId) {
      this.userPrivilegesData = this.resetUserPrivileges(companyId);
      this.setUserPrivilegesData();
    },
  },
  async mounted() {
    const loading = this.$loading.show();
    Promise.all([
      this.getCompanies(),
      this.$store.dispatch('common/getUserPrivileges'),
      this.$store.dispatch('auth/getUserInfor'),
      this.id ? this.$store.dispatch('settingUser/getUserDetail', this.id) : null,
    ]).finally(() => {
      if (this.id) {
        this.formData = { ...this.formData, ...this.userDetail, company: {...this.userDetail.company} };
        this.formData.companyId = this.formData.company.id;
        this.companyName = this.formData.company.name;
        this.initialCompany = {
          id: this.userDetail?.company?.id,
          name: this.userDetail?.company?.name
        }
        for (let el in this.formData.company) {
          this.formData.company[el] = '';
        }
      } else {
        this.formData.role = this.$permission.isOffice() ? this.formData.role.filter(e => this.userChildRoles.includes(e)) : this.formData.role;
      }
      if (this.userDetail?.company?.parent?.id) {
        this.userPrivilegesData = this.resetUserPrivileges(this.userDetail?.company?.parent);
      } else {
        this.userPrivilegesData = this.userPrivileges;
      }
      this.setUserPrivilegesData();
      this.$loading.clear(loading);
      this.$emit('footer-show');
    });
  },
};
